<template>
  <section>
    {{ $t('errors.0.401') }}
  </section>
</template>

<script>
export default {
  name: 'Error401',
};
</script>
